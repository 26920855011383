import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({ height: '*' })),
      state('collapsed', style({ height: '0px' })),
      transition('expanded => collapsed', [animate('0.25s ease-in')]),
      transition('collapsed => expanded', [animate('0.25s ease-in')])
    ]),
  ],
  // tslint:disable-next-line: component-selector
  selector: 'key-section-frame',
  templateUrl: './section-frame.component.html',
  styleUrls: ['./section-frame.component.scss']
})
export class SectionFrameComponent {
  @Input() collapsible: boolean;
  @Input() title: string;

  @Input() set isCollapsed(isCollapsed: boolean) {
    this._isCollapsed = isCollapsed;
    this.isCollapsedChange.emit(isCollapsed);
  }

  @Output() isCollapsedChange = new EventEmitter<boolean>();

  // tslint:disable-next-line: adjacent-overload-signatures
  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  // tslint:disable-next-line: variable-name
  private _isCollapsed: boolean;
}
