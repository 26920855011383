<div class="section-frame" [ngClass]="{ 'collapsed': isCollapsed }">
  <div class="section-header">
    <h3 class="section-title">
      {{ title }}
      <ng-content select="section-title"></ng-content>
    </h3>
    <div class="section-controls" *ngIf="collapsible">
      <a class="toggle" (click)="isCollapsed = !isCollapsed">
        <i class="fa" [ngClass]="{ 'fa-angle-down': isCollapsed, 'fa-angle-up': !isCollapsed }"></i>
      </a>
    </div>
  </div>
    <div class="section-body" [@expandCollapse]="isCollapsed ? 'collapsed' : 'expanded'">
        <div>
            <ng-content select="section-body"></ng-content>
        </div>
    </div>
</div>
