import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BigSpinnerModule, ButtonModule, DataService, ServerSettingsDataService,
  SpinnerModule, TranslateModule, TranslatePipe
} from '@keystone-angular/core';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxHtmlEditorModule } from 'devextreme-angular/ui/html-editor';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IfCompanyAdminDirective } from './auth/if-company-admin.directive';
import { IfCompanyOrSuperAdminDirective } from './auth/if-company-or-super-admin.directive';
import { IfEnterpriseCustomerDirective } from './auth/if-enterprise-customer.directive';
import { IfEnterprisePreviewCompanyAdminDirective } from './auth/if-enterprise-preview-company-admin.directive';
import { IfNonEnterpriseCustomerDirective } from './auth/if-non-enterprise-customer.directive';
import { IfSuperAdminDirective } from './auth/if-super-admin.directive';
import { PermissionDirective } from './auth/permission.directive';
import { DragHandleDirective } from './drag-and-drop/drag-handle.directive';
import { DraggableDirective } from './drag-and-drop/draggable.directive';
import { DroppableDirective } from './drag-and-drop/droppable.directive';
import { UnauthorizedHttpInterceptorService } from './http/unauthorized-http-interceptor.service';
import { LocalDatePipe } from './localisation/local-date.pipe';
import { LocalNumberPipe } from './localisation/local-number.pipe';
import { LocalTimePipe } from './localisation/local-time.pipe';
import { ConfirmationModalComponent } from './modal/confirmation-modal/confirmation-modal.component';
import { ModalService } from './modal/modal.service';
import { SessionTimeoutModalComponent } from './modal/session-timeout-modal/session-timeout-modal.component';
import { SpinnerModalComponent } from './modal/spinner-modal/spinner-modal.component';
import { RouterBackDirective } from './navigation/router-back.directive';
import { AvatarComponent } from './ui/avatar/avatar.component';
import { DatePickerComponent } from './ui/date-picker/date-picker.component';
import { DateRangeComponent } from './ui/date-range/date-range.component';
import { DropdownButtonComponent } from './ui/dropdown-button/dropdown-button.component';
import { FileUploadComponent } from './ui/file-upload/file-upload.component';
import { FilterButtonComponent } from './ui/filter-button/filter-button.component';
import { BooleanFilterComponent } from './ui/filter-button/filters/boolean-filter/boolean-filter.component';
import { DateFilterComponent } from './ui/filter-button/filters/date-filter/date-filter.component';
import { FilterComponent } from './ui/filter-button/filters/filter.component';
import { MultiSelectFilterComponent } from './ui/filter-button/filters/multi-select-filter/multi-select-filter.component';
import { SelectFilterComponent } from './ui/filter-button/filters/select-filter/select-filter.component';
import { FilteredSearchComponent } from './ui/filtered-search/filtered-search.component';
import { CheckboxFieldComponent } from './ui/form-fields/checkbox-field/checkbox-field.component';
import { MinimumTaxableAmountFieldComponent } from './ui/form-fields/minimum-taxable-amount-field/minimum-taxable-amount-field.component';
import { ProductGroupSelectFieldComponent } from './ui/form-fields/product-group-select-field/product-group-select-field.component';
import { SwitchFieldComponent } from './ui/form-fields/switch-field/switch-field.component';
import { TagFieldComponent } from './ui/form-fields/tag-field/tag-field.component';
import { TextFieldComponent } from './ui/form-fields/text-field/text-field.component';
import { CarouselComponent } from './ui/images/carousel/carousel.component';
import { DropzoneComponent } from './ui/images/dropzone/dropzone.component';
import { ImageDropzoneComponent } from './ui/images/image-dropzone/image-dropzone.component';
import { InfiniteProgressBarComponent } from './ui/infinite-progress-bar/infinite-progress-bar.component';
import { CheckboxComponent } from './ui/inputs/checkbox/checkbox.component';
import { SpinnerLabelComponent } from './ui/labels/spinner-label/spinner-label.component';
import { SectionFrameComponent } from './ui/layout/section-frame/section-frame.component';
import { HideWhenNoSubMenuDirective } from './ui/menu/hide-when-no-sub-menu.directive';
import { EnumToArrayPipe } from './ui/pipes/enum-to-array.pipe';
import { FilterPipe } from './ui/pipes/filter.pipe';
import { GenderPipe } from './ui/pipes/gender.pipe';
import { ImagePipe } from './ui/pipes/image.pipe';
import { InArrayPipe } from './ui/pipes/in-array.pipe';
import { OrderByPipe } from './ui/pipes/order-by.pipe';
import { RejectPipe } from './ui/pipes/reject.pipe';
import { UniqByPipe } from './ui/pipes/uniq-by.pipe';
import { ProgressBarComponent } from './ui/progress-bar/progress-bar.component';
import { RibbonComponent } from './ui/ribbon/ribbon.component';
import { SearchBoxComponent } from './ui/search-box/search-box.component';
import { TinyLoaderComponent } from './ui/tiny-loader/tiny-loader.component';
import { UserActionLabelComponent } from './ui/user-action/user-action-label.component';
import { WaitComponent } from './ui/wait/wait.component';
import { UnderDevelopmentDirective } from './auth/under-development.directive';
import { EditableFieldComponent } from './ui/form-fields/editable-field/editable-field.component';
import { TagDialogComponent } from './ui/dialog/tag-dialog/tag-dialog.component';
import { OnlyInProductionDirective } from './auth/only-in-production.directive';
import { If2025Directive } from './auth/if2025.directive';
import { IfNot2025Directive } from './auth/if-not2025.directive';

const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedHttpInterceptorService, multi: true }
];

@NgModule({
  declarations: [
    AvatarComponent,
    FilterPipe,
    FilterComponent,
    ImagePipe,
    ImageDropzoneComponent,
    PermissionDirective,
    RejectPipe,
    UniqByPipe,
    LocalDatePipe,
    IfSuperAdminDirective,
    IfCompanyAdminDirective,
    IfEnterprisePreviewCompanyAdminDirective,
    OrderByPipe,
    LocalTimePipe,
    ConfirmationModalComponent,
    SpinnerModalComponent,
    LocalNumberPipe,
    TinyLoaderComponent,
    EnumToArrayPipe,
    SearchBoxComponent,
    FilteredSearchComponent,
    UserActionLabelComponent,
    FilterButtonComponent,
    BooleanFilterComponent,
    SelectFilterComponent,
    MultiSelectFilterComponent,
    CheckboxComponent,
    ProgressBarComponent,
    GenderPipe,
    DatePickerComponent,
    SectionFrameComponent,
    DropzoneComponent,
    DropdownButtonComponent,
    FileUploadComponent,
    WaitComponent,
    InfiniteProgressBarComponent,
    RibbonComponent,
    DateRangeComponent,
    InArrayPipe,
    RouterBackDirective,
    SpinnerLabelComponent,
    TextFieldComponent,
    SwitchFieldComponent,
    TagFieldComponent,
    CheckboxFieldComponent,
    CarouselComponent,
    DraggableDirective,
    DroppableDirective,
    HideWhenNoSubMenuDirective,
    DragHandleDirective,
    DateFilterComponent,
    SessionTimeoutModalComponent,
    MinimumTaxableAmountFieldComponent,
    ProductGroupSelectFieldComponent,
    IfNonEnterpriseCustomerDirective,
    IfEnterpriseCustomerDirective,
    IfCompanyOrSuperAdminDirective,
    UnderDevelopmentDirective,
    OnlyInProductionDirective,
    EditableFieldComponent,
    TagDialogComponent,
    If2025Directive,
    IfNot2025Directive
  ],
  entryComponents: [
    BooleanFilterComponent,
    ConfirmationModalComponent,
    MultiSelectFilterComponent,
    SelectFilterComponent,
    SessionTimeoutModalComponent,
    SpinnerModalComponent
  ],
  exports: [
    AvatarComponent,
    BigSpinnerModule,
    CarouselComponent,
    CheckboxComponent,
    CheckboxFieldComponent,
    DatePickerComponent,
    DateRangeComponent,
    DropzoneComponent,
    EnumToArrayPipe,
    FileUploadComponent,
    FilterButtonComponent,
    FilterPipe,
    FilteredSearchComponent,
    GenderPipe,
    HideWhenNoSubMenuDirective,
    IfSuperAdminDirective,
    IfCompanyAdminDirective,
    IfCompanyOrSuperAdminDirective,
    IfEnterprisePreviewCompanyAdminDirective,
    ImageDropzoneComponent,
    ImagePipe,
    InArrayPipe,
    InfiniteProgressBarComponent,
    LocalDatePipe,
    LocalNumberPipe,
    LocalTimePipe,
    MinimumTaxableAmountFieldComponent,
    ModalModule,
    OrderByPipe,
    PermissionDirective,
    ProductGroupSelectFieldComponent,
    ProgressBarComponent,
    RejectPipe,
    RibbonComponent,
    RouterBackDirective,
    SearchBoxComponent,
    SectionFrameComponent,
    SpinnerLabelComponent,
    SpinnerModule,
    SwitchFieldComponent,
    TabsModule,
    TagFieldComponent,
    TextFieldComponent,
    TinyLoaderComponent,
    TooltipModule,
    TranslateModule,
    UniqByPipe,
    UserActionLabelComponent,
    WaitComponent,
    IfNonEnterpriseCustomerDirective,
    IfEnterpriseCustomerDirective,
    UnderDevelopmentDirective,
    OnlyInProductionDirective,
    EditableFieldComponent,
    If2025Directive,
    IfNot2025Directive
  ],
  imports: [
    BigSpinnerModule,
    BsDropdownModule.forRoot(),
    ButtonModule,
    CommonModule,
    DxDateBoxModule,
    DxFileUploaderModule,
    DxHtmlEditorModule,
    DxPopoverModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxTemplateModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    SpinnerModule,
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TranslateModule
  ],
  providers: [
    CurrencyPipe,
    DataService,
    DatePipe,
    DecimalPipe,
    FilterPipe,
    GenderPipe,
    httpInterceptorProviders,
    ImagePipe,
    LocalDatePipe,
    LocalNumberPipe,
    ModalService,
    OrderByPipe,
    ServerSettingsDataService,
    TranslatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FrameworkModule { }
