import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { TokenService } from '@keystone-angular/core';
import { filter, Subject, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationManager } from '../../authentication/authentication-manager.service';
import { ModalService } from '../modal/modal.service';
import { SessionTimeoutModalComponent } from '../modal/session-timeout-modal/session-timeout-modal.component';
import { IdleDetectorService } from './idle-detector.service';

export interface ClaimsPrincipal {
  calculateAverageCost: any;
  claims: any;
  company: {
    autoArchiveDeliveries: boolean;
    averageCost: boolean;
    disableCostPerStock: boolean;
    // TODO: We should include this property in the token
    name: string,
    preventInternalGTINgeneration: boolean;
  };
  companyId: number;
  currentRole: string;
  currentRoleId: number;
  email: string;
  fullName: string;
  id: string;
  isCompanyAdmin: boolean;
  isSuperAdmin: boolean;
  locale: string;
  personCreatedDate: Date;
  regionId: number;
  regionName: string;
  regionTimeZone: string;
  settings: any;
  stockId: number;
  stockName: string;
  store: {
    currentSubscription: string;
    showFeaturesInPreview: boolean;
  };
  storeId: number;
  storeCluster: string;
  storeName: string;
  usePrintBeforeReception: boolean;
  userCreatedDate: Date;
  useRfid: boolean;
  userId: number;
  userName: string;
  useStockshelf: boolean;
  hasLeakedPassword: boolean;
}

type IdTokenClaims = {
  acr: string;
  at_hash: string;
  aud: string;
  auth_time: number;
  autoArchiveDeliveries: boolean;
  calculateAverageCost: boolean;
  averageCost: boolean;
  companyId: number;
  currentRole: string;
  currentRoleId: number;
  currentSubscription: string;
  defaultPrinterId: string;
  disableCostPerStock: boolean;
  email: string;
  enablePrint: boolean;
  exp: number;
  fullName: string;
  iat: number;
  isCompanyAdmin: boolean;
  iss: string;
  isStoreManager: boolean;
  isSuperAdmin: boolean;
  locale: string;
  name: string;
  nbf: number;
  nonce: string;
  preventInternalGTINgeneration: boolean;
  regionId: number;
  regionName: string;
  regionTimeZone: string;
  showFeaturesInPreview: boolean;
  stockId: number;
  stockName: string;
  storeId: number;
  storeCluster: string;
  storeName: string;
  sub: string;
  tid: string;
  userId: number;
  useRfid: boolean;
  usePrintBeforeReception: boolean;
  useStockshelf: boolean;
  ver: string;
  hasLeakedPassword: boolean;
};

const MINUTES_UNTIL_AUTO_LOGOUT = 60 * 8;
const USER_LIFE_TIME_MILLISECONDS = 5 * 1000;

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  sessionTimeout = new Subject();

  get user(): ClaimsPrincipal {
    if (!this._user) {
      if (this._isUsingB2C) {
        this.getUserB2C();
      }
      else {
        this.getUserLegacy();
      }
    }

    return this._user;
  }

  // tslint:disable-next-line: variable-name
  private _user: ClaimsPrincipal;
  private _isUsingB2C: boolean;
  private _companyCache: { name: string, id: number };

  constructor(private idleDetectorService: IdleDetectorService,
    private modalService: ModalService,
    private msalBroadcastService: MsalBroadcastService,
    private authManager: AuthenticationManager,
    private msalService: MsalService,
    private tokenService: TokenService) {

    this.idleDetectorService.startDetecting(MINUTES_UNTIL_AUTO_LOGOUT * 60).subscribe(this.onIdle.bind(this));

    const intervalId = setInterval(() => {
      if (this.authManager.settingsLoaded()) {
        this.setUpB2C();
        clearInterval(intervalId);
      }
    }, 500);
  }

  setUpB2C(): void {
    this._isUsingB2C = this.authManager.isUsingB2C();

    if (this._isUsingB2C) {
      this.msalBroadcastService.msalSubject$
        .pipe(filter(message => message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
        .subscribe(message => {
          const payload = message.payload as AuthenticationResult;
          this._user = this.getPrincipalFromIdTokenClaims(payload.idTokenClaims as IdTokenClaims);
        });
    }

    this.tokenService.subscribeToTokenChange(token => this._user = this.getPrincipalFromToken(token));
  }

  hasChangedStock(stockId: number): boolean {
    if (this._isUsingB2C) {
      const nonCachedClaims = this.msalService.instance.getAllAccounts()[0]?.idTokenClaims as IdTokenClaims;
      return nonCachedClaims == null || nonCachedClaims.stockId !== stockId;
    }

    return this.user?.stockId !== stockId;
  }

  getStockCostTypeIndex(): string {
    if (!this.user?.company || this.user?.company?.disableCostPerStock) {
      return '2';
    }
    if (!this.user?.company.disableCostPerStock && this.user?.company?.averageCost) {
      return '1';
    }
    if (!this.user?.company.disableCostPerStock && !this.user?.company?.averageCost) {
      return '1';
    }
  }

  setUserSettings(settings: any): void {
    if (this._user) {
      this._user.settings = settings;
    }
  }

  updateCompanyName(companyName: string, stockName: string): void {
    this._companyCache = { name: companyName, id: this._user.companyId };

    this._user.company.name = companyName;
    this._user.stockName = stockName;
  }

  private deserializeToken(token: string): any {
    if (token != null) {
      const data: any = atob(token.split(':')[0]).split('\n');

      data[0] = data[0] || '';
      data[1] = data[1] ? data[1].split('|') : [];

      return data;
    }

    return ['', []];
  }

  private getUserB2C() {
    const idTokenClaims = this.msalService.instance.getAllAccounts()[0]?.idTokenClaims;
    this._user = !idTokenClaims
      ? {} as any
      : this.getPrincipalFromIdTokenClaims(idTokenClaims as IdTokenClaims);
  }

  private getUserLegacy() {
    this._user = this.getPrincipalFromToken(this.tokenService.getToken());

    if (this._user && this._user.company) {
      this._user.company.name = this.getCompanyName();
    }

    timer(USER_LIFE_TIME_MILLISECONDS).subscribe(_ => this._user = null);
  }

  private getPrincipalFromIdTokenClaims(idTokenClaims: IdTokenClaims): ClaimsPrincipal {
    return {
      calculateAverageCost: idTokenClaims.calculateAverageCost,
      claims: null,
      company: {
        averageCost: idTokenClaims.averageCost,
        autoArchiveDeliveries: idTokenClaims.autoArchiveDeliveries,
        disableCostPerStock: idTokenClaims.disableCostPerStock,
        name: this.getCompanyName(),
        preventInternalGTINgeneration: idTokenClaims.preventInternalGTINgeneration
      },
      companyId: idTokenClaims.companyId,
      currentRole: idTokenClaims.currentRole,
      currentRoleId: idTokenClaims.currentRoleId,
      email: idTokenClaims.email,
      fullName: idTokenClaims.fullName,
      id: idTokenClaims.sub,
      isCompanyAdmin: idTokenClaims.isCompanyAdmin,
      isSuperAdmin: idTokenClaims.isSuperAdmin,
      locale: idTokenClaims.locale,
      personCreatedDate: null,
      regionId: idTokenClaims.regionId,
      regionName: idTokenClaims.regionName,
      regionTimeZone: idTokenClaims.regionTimeZone,
      settings: [],
      stockId: idTokenClaims.stockId,
      stockName: idTokenClaims.stockName,
      store: {
        currentSubscription: idTokenClaims.currentSubscription,
        showFeaturesInPreview: idTokenClaims.showFeaturesInPreview
      },
      storeId: idTokenClaims.storeId,
      storeName: idTokenClaims.storeName,
      storeCluster: idTokenClaims.storeCluster,
      usePrintBeforeReception: idTokenClaims.usePrintBeforeReception,
      userCreatedDate: null,
      userId: idTokenClaims.userId,
      userName: null,
      useStockshelf: idTokenClaims.useStockshelf,
      useRfid: idTokenClaims.useRfid,
      hasLeakedPassword: false
    }
  }

  private getPrincipalFromToken(token: string): ClaimsPrincipal {
    if (token) {
      try {
        const userInfo = this.deserializeToken(token);
        const userName = userInfo.shift().replace(/[\u0000]/g, '');
        const claims = userInfo.shift();
        const userData = JSON.parse(claims.shift().replace(/[\u0000]/g, '') || '{}');

        return {
          calculateAverageCost: userData.stock.calculateAverageCost,
          claims,
          company: {
            averageCost: userData.company.AverageCost,
            autoArchiveDeliveries: userData.company.AutoArchiveDeliveries,
            disableCostPerStock: userData.company.DisableCostPerStock,
            name: '',
            preventInternalGTINgeneration: userData.company.PreventInternalGTINgeneration
          },
          companyId: Number(userData.companyId),
          currentRole: userData.currentRole,
          currentRoleId: userData.currentRoleId,
          email: userData.email,
          fullName: userData.fullName,
          id: userData.id,
          isCompanyAdmin: userData.isCompanyAdmin,
          isSuperAdmin: userData.isSuperAdmin,
          locale: userData.locale,
          personCreatedDate: userData.personCreatedDate,
          regionId: !userData.regionId ? undefined : Number(userData.regionId),
          regionName: userData.regionName,
          regionTimeZone: userData.regionTimeZone,
          settings: [],
          stockId: Number(userData.stockId),
          stockName: userData.stockName,
          store: {
            currentSubscription: userData.store.CurrentSubscription,
            showFeaturesInPreview: userData.store.ShowFeaturesInPreview
          },
          storeId: Number(userData.storeId),
          storeName: userData.storeName,
          storeCluster: userData.storeCluster,
          usePrintBeforeReception: userData.stock.UsePrintBeforeReception,
          userCreatedDate: userData.userCreatedDate,
          useRfid: userData.stock.UseRfid,
          userId: Number(userData.userId),
          userName,
          useStockshelf: userData.stock.UseStockshelf,
          hasLeakedPassword: userData.hasLeakedPassword
        };
      } catch {
        this.authManager.logOut().subscribe(response => {
          if (!this._isUsingB2C) {
            location.assign(environment.loginApp);
          }
        });
      }
    }
  }

  private getCompanyName(): string {
    if (this._user && this._user.company) {
      return this._companyCache != null && this._companyCache.id === this._user.companyId
        ? this._companyCache.name || '' : '';
    }

    return '';
  }

  private onIdle(): void {
    const sessionTimeoutModal = this.modalService.open(SessionTimeoutModalComponent, {
      backdrop: 'static'
    });

    const sessionTimeoutModalHideSubscription =
      (sessionTimeoutModal.content as SessionTimeoutModalComponent).hide.subscribe(continueSession => {
        sessionTimeoutModalHideSubscription.unsubscribe();
        if (continueSession) {
          this.idleDetectorService.startDetecting(MINUTES_UNTIL_AUTO_LOGOUT * 60).subscribe(this.onIdle.bind(this));
        } else {
          this.sessionTimeout.next(null);
        }
      });
  }
}
