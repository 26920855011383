import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '@keystone-angular/core';
import { ContextService } from './framework/auth/context.service';
import { ConfirmationModalComponent } from './framework/modal/confirmation-modal/confirmation-modal.component';
import { ModalService } from './framework/modal/modal.service';
import { RouterHistoryService } from './framework/navigation/router-history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private confirmationModal: ConfirmationModalComponent;

  constructor(routerHistoryService: RouterHistoryService,
    private modalService: ModalService, private translatePipe: TranslatePipe,
    private contextService: ContextService) {
    routerHistoryService.start();
  }

  ngOnInit() {
    if (this.hasLeakedPassword()) {
      const modal = this.modalService.open(ConfirmationModalComponent, {});
      this.confirmationModal = (modal.content as ConfirmationModalComponent);

      this.confirmationModal.canClose = false;
      this.confirmationModal.canCancel = false;
      this.confirmationModal.title = 'App_Warning';
      this.confirmationModal.message = 'App_PasswordLeaked';
      this.confirmationModal.okText = this.translatePipe.transform('App_Confirm');
      this.confirmationModal.okClass = 'btn-primary';
    }
  }

  hasLeakedPassword(): boolean {
    return this.contextService?.user?.hasLeakedPassword || false;
  }
}
