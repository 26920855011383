import { Component, Input } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { FilterDefinition } from '../../../filtered-search/models/filter-definition';
import { FilterComponent } from '../filter.component';

@Component({
  selector: 'app-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss']
})
export class MultiSelectFilterComponent extends FilterComponent<string[]> {
  @Input() options: any[] | DataSource;
  @Input() display: string;
  @Input() return: string;

  // tslint:disable-next-line: variable-name
  private _bodyClickFn: any;

  initFromData(filterDefinition: FilterDefinition, filterValue: any) {
    super.initFromData(filterDefinition, filterValue);

    this.value = filterValue;
    this.options = filterDefinition.source != null ? filterDefinition.source('') : [];
    this.display = filterDefinition.display;
    this.return = filterDefinition.return;
  }

  handleSelection(selection: any) {
    this.value = selection.value || null;
  }

  blockBody() {
    this._bodyClickFn = document.body.onclick;

    document.body.onclick = this.preventDefaultAndStopPropagation;
  }

  releaseBody() {
    document.body.onclick = this._bodyClickFn;
  }

  private preventDefaultAndStopPropagation(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }
}
